import { type RemovableRef, useStorage } from '@vueuse/core'
import { Currency, StoreStatus } from '@rediredi/types'
import { LOCALE_SITE_MAP } from '~/consts/internationalization'
import { StorefrontStore } from '~/types/store'

const defaultTheme = () => ({
  primary: '#8693B4',
  secondary: '#737F9C',
  text: '#FFFFFF',
})

const CONFIG_STORAGE_KEY = 'STOREFRONT_BASIC_CONFIG'

export type MobileProductCellType = 'list' | 'grid'

export type Config = {
  mobileProductCellVariant: MobileProductCellType
  isAdult?: boolean
}

export const useGlobalStore = defineStore('global', {
  state: (): {
    headerLogoVisible: boolean
    store: StorefrontStore
    config: RemovableRef<Config>
    backButtonBehavior?: () => void
    blurredContent: boolean
  } => {
    return {
      blurredContent: false,
      headerLogoVisible: false,
      store: {
        hasMatureContent: undefined,
        countryCode: undefined,
        id: '',
        isBillingAddressTheCompanyAddress: false,
        name: '',
        handle: '',
        description: '',
        aboutUs: '',
        themeColorScheme: {
          ...defaultTheme(),
        },
        socialNetworkUris: {
          youtube: '',
          twitter: '',
          instagram: '',
          facebook: '',
        },
        paymentMethodsOffered: {},
        displayOutOfStockItems: false,
        salesperson: undefined,
      },
      config: useStorage<Config>(CONFIG_STORAGE_KEY, {
        mobileProductCellVariant: 'grid',
        isAdult: undefined,
      }),
      backButtonBehavior: undefined,
    }
  },

  getters: {
    theme: (state) => {
      return state.store.themeColorScheme ?? defaultTheme()
    },

    storeIsAvailable: (state) => {
      return (
        !!state.store.status &&
        [StoreStatus.Active, StoreStatus.Inactive].includes(state.store.status)
      )
    },

    pageTitle: (state) => {
      if (state.store.salesperson?.name) {
        return `${state.store.name} | ${state.store.salesperson.name}`
      }

      return state.store.name
    },

    whatsappNumber: (state) => {
      const whatsapp = state.store.whatsappPhoneNumber

      if (!whatsapp) return ''

      if (typeof whatsapp === 'string') return whatsapp

      const countryCode = whatsapp.countryCode || ''
      const areaCode = whatsapp.areaCode ? `(${whatsapp.areaCode})` : ''

      return `${countryCode} ${areaCode} ${whatsapp.number}`
    },

    salespersonWhatsappNumber: (state) => {
      const whatsapp = state.store.salesperson?.whatsAppNumber || state.store.whatsappPhoneNumber

      if (typeof whatsapp === 'string') return whatsapp

      if (!whatsapp) return ''

      const countryCode = whatsapp.countryCode || ''
      const areaCode = whatsapp.areaCode ? `(${whatsapp.areaCode})` : ''

      return `${countryCode} ${areaCode} ${whatsapp.number}`
    },

    phoneNumber: (state) => {
      const phone = state.store.phoneNumber

      if (!phone) return ''

      if (typeof phone === 'string') return phone

      const countryCode = phone.countryCode || ''
      const areaCode = phone.areaCode ? `(${phone.areaCode})` : ''

      return `${countryCode} ${areaCode} ${phone.number}`
    },

    contactNumber: (state) => {
      const salesperson = state.store.salesperson

      if (salesperson?.whatsAppNumber?.number) {
        const { countryCode, areaCode, number } = salesperson.whatsAppNumber
        return `${countryCode || ''} ${areaCode || ''} ${number}`
      }

      const phone = state.store.phoneNumber

      if (!phone) return ''

      if (typeof phone === 'string') return phone

      const countryCode = phone.countryCode || ''
      const areaCode = phone.areaCode ? `(${phone.areaCode})` : ''

      return `${countryCode} ${areaCode} ${phone.number}`
    },

    email: (state) => {
      if (state.store.salesperson?.email) {
        return state.store.salesperson.email || state.store.email
      }

      return state.store.email
    },

    storeAddress: (state) => {
      const {
        addressLine1,
        addressLine2,
        neighborhood,
        city,
        stateOrRegion,
        zipCode,
        streetNumber,
      } = state.store.companyAddress || {}
      let addressStr = ''

      if (addressLine1) {
        addressStr += addressLine1
      }

      if (streetNumber) {
        addressStr += `, ${streetNumber}`
      }

      if (addressLine2) {
        addressStr += `, ${addressLine2}`
      }

      if (neighborhood) {
        addressStr += ` - ${neighborhood}`
      }

      if (city) {
        addressStr += `, ${city}`
      }

      if (stateOrRegion) {
        addressStr += ` - ${stateOrRegion}`
      }

      if (zipCode) {
        addressStr += `\n ${zipCode}`
      }

      return addressStr
    },

    mapsAddress: (state) => {
      const { addressLine1, city, zipCode, streetNumber } = state.store.companyAddress || {}

      if (!addressLine1 && !streetNumber && !city && !zipCode) return ''

      return `${addressLine1} ${streetNumber}, ${city} - ${zipCode}`
    },

    isCheckoutEnabled: (state) => state.store.checkoutSettings?.isCartEnabled,

    storeCurrency: (state) => state.store.currency || Currency.BRL,

    siteURL: (state) => `https://rediredi.com/${LOCALE_SITE_MAP[state.store.locale || 'en']}`,

    salespersonUrl: (state) => {
      if (process.client && state.store.salesperson) {
        return `${window.location.origin}/s/${state.store.salesperson.handle}`
      }

      return ''
    },
  },

  actions: {
    getStoreSEOTags() {
      const store = this.store

      return {
        title: store.name,
        ogTitle: store.name,
        description: store.description,
        ogDescription: store.description,
        ogImage: optimizedImage(getPictureUrl(store.backgroundImageUri), 'small'),
        ogImageSecureUrl: optimizedImage(getPictureUrl(store.backgroundImageUri), 'small'),
        ogType: 'website',
        ogImageWidth: 300,
        ogImageHeight: 300,
        twitterCard: 'summary_large_image',
      }
    },

    async loadSalesperson(salespersonHandle: string) {
      const qs = new URLSearchParams({ salespersonHandle }).toString()
      const store = await useFetchWrapper<StorefrontStore>(
        `/stores/storefront/${this.store.handle}?${qs}`,
        'GET',
      )
      this.store.salesperson = store.salesperson
      return store
    },
  },

  hydrate(state, _) {
    const config = useStorage<Config>(
      CONFIG_STORAGE_KEY,
      {
        mobileProductCellVariant: 'grid',
        isAdult: undefined,
      },
      localStorage,
      { mergeDefaults: true },
    )

    // @ts-ignore next-line
    state.config = config
  },
})
