<script lang="ts" setup>
import { UnleashClient } from '@unleash/proxy-client-vue'

const route = useRoute()
const theme = useStoreTheme()
const globalStore = useGlobalStore()

const isModalOpen = computed(() => {
  return Boolean(globalStore.store.hasMatureContent && !globalStore.config.isAdult)
})

const loadGlobalCSSVariables = () => {
  const root = document.documentElement

  root.style.setProperty('--color-text', theme.text)
  root.style.setProperty('--color-primary', theme.primary)
  root.style.setProperty('--color-secondary', theme.secondary)
  root.style.setProperty('--color-header', `${theme.primary}F2`)
}

useHead({
  meta: [
    {
      name: 'theme-color',
      content: theme.primary,
    },
  ],
  htmlAttrs: {
    lang: globalStore.store.locale,
  },
})

const updateUnleashClient = () => {
  const unleashClient = inject('unleashClient') as UnleashClient

  const contextFields = {
    properties: {
      storeId: globalStore.store.id,
      storeHandle: globalStore.store.handle!,
    },
  }

  unleashClient.updateContext(contextFields)
}

if (process.client) {
  updateUnleashClient()
}

watch(route, () => {
  const { metaEvent } = useEvent()
  metaEvent('PageView', route.name)
})

onMounted(() => {
  loadGlobalCSSVariables()
})
</script>

<template>
  <div>
    <div id="layout-container">
      <NuxtLayout>
        <div id="page-container" :class="{ 'blur-2xl': globalStore.blurredContent }">
          <NuxtPage />
        </div>
      </NuxtLayout>
    </div>

    <div
      id="modals"
      class="modals pointer-events-none fixed top-0 z-30 h-screen w-full transition-colors"
    ></div>

    <RMatureContentModal :is-open="isModalOpen" />
  </div>
</template>

<style>
:root {
  --background-backdrop: #29354f4d;
}

.text--primary {
  color: var(--color-primary);
}

.background--primary {
  background-color: var(--color-primary);
}

.text--secondary {
  color: var(--color-secondary);
}

.background--secondary {
  background-color: var(--color-secondary);
}

*:focus {
  outline-width: 1px;
  outline-offset: solid;
  outline-color: var(--color-secondary);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.18s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
.modals {
  &.active-backdrop {
    background-color: var(--background-backdrop);
  }
  & > * {
    pointer-events: all;
  }
}
</style>
