import { useGtm } from '@gtm-support/vue-gtm'
import { FacebookQuery } from 'meta-pixel'

export type EventName =
  | 'add_payment_info'
  | 'add_personal_info'
  | 'add_shipping_info'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'begin_checkout'
  | 'generate_lead'
  | 'purchase'
  | 'search'
  | 'select_item'
  | 'share'
  | 'view_cart'
  | 'view_item'
  | 'view_search_results'
  | 'product_catalog_deactivated_button_click'

export type MetaEventName =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'InitiateCheckout'
  | 'Lead'
  | 'Purchase'
  | 'Search'
  | 'ViewContent'
  | 'PageView'

export const useEvent = () => {
  const gtm = useGtm()
  const globalStore = useGlobalStore()

  const event = (name: EventName, value: object = {}, key = 'ecommerce') => {
    const enhancedPayload: Record<string, any> = { ...value }

    if (globalStore.store) {
      enhancedPayload.rr_store_id = globalStore.store.id ?? ''
      enhancedPayload.rr_store_host = window.location.host
      enhancedPayload.rr_store_handle = globalStore.store.handle ?? ''
    }

    if (globalStore.store.salesperson) {
      enhancedPayload.rr_salesperson_id = globalStore.store.salesperson.id
      enhancedPayload.rr_salesperson_handle = globalStore.store.salesperson.handle
    }

    gtm?.trackEvent({ event: name, [key]: enhancedPayload })
  }

  return {
    event,
    metaEvent: (event: MetaEventName, payload: unknown) => {
      const metaEvent = inject('fbq') as FacebookQuery
      const fbq = (metaEvent || (() => {})) as FacebookQuery

      if (globalStore.store.storefrontPreferences?.analytics?.metaPixelId) {
        fbq('track', event, payload)
      }
    },
  }
}
