const BLOCKED_STORE_ALLOWED_ROUTES = ['/not-available', '/about']

export default defineNuxtRouteMiddleware((to, from) => {
  const globalStore = useGlobalStore()

  if (!BLOCKED_STORE_ALLOWED_ROUTES.includes(to.path) && !globalStore.storeIsAvailable) {
    return navigateTo('/not-available')
  }

  // If the salesperson handle exists, we redirect to the same route with the handle
  if (from.params.salesperson && !to.params.salesperson) {
    return navigateTo(`/s/${from.params.salesperson}${to.path}`)
  }
})
