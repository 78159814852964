import type { RouterConfig } from '@nuxt/schema'
import { RouteRecordRaw } from 'vue-router'

export default {
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: (_routes) =>
    [
      ..._routes.map((route) => ({
        ...route,
        name: `salesperson-${route.name?.toString()}`,
        path: `/s/:salesperson${route.path}`,
      })),
      ..._routes,
    ] as RouteRecordRaw[],
} satisfies RouterConfig
