import { createGtm } from '@gtm-support/vue-gtm'
import { addScriptDefault } from 'meta-pixel'
import VueGtag from 'vue-gtag'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const globalStore = useGlobalStore()
  const runtimeConfig = useRuntimeConfig()

  window.dataLayer = window.dataLayer || []

  const payload: Record<string, any> = {
    event: 'config',
    rr_store_id: globalStore.store.id,
    rr_store_host: window.location.host,
    rr_store_handle: globalStore.store.handle,
  }

  if (globalStore.store.salesperson) {
    payload.rr_salesperson_id = globalStore.store.salesperson.id
    payload.rr_salesperson_handle = globalStore.store.salesperson.handle
  }

  window.dataLayer.push(payload)

  runtimeConfig.public.GTM_CONTAINER_ID &&
    nuxtApp.vueApp.use(
      createGtm({
        id: runtimeConfig.public.GTM_CONTAINER_ID,
        defer: true,
        trackOnNextTick: false,
        vueRouter: router,
        vueRouterAdditionalEventData: () => ({
          rr_store_id: globalStore.store.id,
          rr_store_host: window.location.host,
          rr_store_handle: globalStore.store.handle,
          rr_salesperson_id: globalStore.store.salesperson?.id,
          rr_salesperson_handle: globalStore.store.salesperson?.handle,
        }),
        enabled: true,
      }),
    )

  if (globalStore.store.storefrontPreferences?.analytics?.googleAnalyticsPropertyId) {
    nuxtApp.vueApp.use(
      VueGtag,
      {
        config: { id: globalStore.store.storefrontPreferences.analytics.googleAnalyticsPropertyId },
      },
      router,
    )
  }

  if (globalStore.store.storefrontPreferences?.analytics?.metaPixelId) {
    const fbq = addScriptDefault()

    fbq('set', 'autoConfig', globalStore.store.storefrontPreferences.analytics.metaPixelId, true)
    fbq('init', globalStore.store.storefrontPreferences.analytics.metaPixelId)
    fbq('track', 'PageView')
    window.fbq = window.fbq || fbq
    nuxtApp.vueApp.provide('fbq', fbq)
  }
})
