<script setup lang="ts">
defineProps<{ name?: string; size?: string }>()

const slots = defineSlots()
</script>

<template>
  <div :class="`r-icon flex justify-center align-middle`">
    <template v-if="slots.default">
      <slot :class="`w-[${size}px] h-[${size}px]`" />
    </template>
    <Icon v-else-if="name" :name="name" :size="size" />
  </div>
</template>
